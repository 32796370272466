<template>
<div class="modules">
    <v-container class="zindex-max visibility-hidden" :class="[$vuetify.breakpoint.smAndDown ? 'p-fixed top20 left5' : '']">
        <v-row>
            <v-col cols="12">
                <v-btn text class="pa-1">
                    <v-icon>mdi-chevron-left</v-icon>
                    Back
                </v-btn>
            </v-col>
        </v-row>
    </v-container>

    <meo-ripple :config="config" v-if="$vuetify.breakpoint.smAndDown"></meo-ripple>

    <v-container :class="{ 'pa-16': $vuetify.breakpoint.smAndUp }">
        <v-row class="p-relative zindex-1 full-width ma-0">
            <v-col cols="12" align="center" justify="center">
                <h3 class="heading--text">Resources</h3>
            </v-col>
        </v-row>

        <v-row justify="center">
            <v-col cols="12" md="4" lg="3" v-for="item in resources" :key="item._id">
                <template v-if="$vuetify.breakpoint.smAndDown">
                    <meo-list-item :item="item" section :purchased="purchased(item)" @click.native="goTo(item)"></meo-list-item>
                </template>

                <template v-else>
                    <meo-card-item :item="item" section :purchased="purchased(item)" @click.native="goTo(item)"></meo-card-item>
                </template>
            </v-col>
        </v-row>
    </v-container>
</div>
</template>

<script>
// Components
import MeoListItem from "@/components/misc/meo-list-item.vue";
import MeoCardItem from "@/components/misc/meo-card-item.vue";
import MeoRipple from "@/components/misc/meo-ripple.vue";

//Services
import ResourceArticle from "@/modules/meo-module/resource-article";
import MeoProgressDocument from "@/modules/meo-module/progress-document";

export default {
    name: "resource-list",

    meta: {
        titleTemplate: "Resources | %s",
    },

    data() {
        return {
            config: {
                image: require("@/assets/logo.png"),
                padding: true,
                // avatar: true,
            },
            products: [],
            resources: [],
        };
    },

    components: {
        MeoListItem,
        MeoCardItem,
        MeoRipple
    },

    methods: {
        async init() {
            let resources = await ResourceArticle.list().then(({ data }) => data);
            this.resources = resources.filter((item) => item.status === "active");
        },
        goTo(item) {
            let id;

            if (item && item._id) id = item._id;
            else id = item;

            this.$router.push({ name: "resource", params: { id } });
        },
        purchased(item) {
            let match = this.products.some((product) => product._id === item._id);
            let free = item.data && item.data.freeProduct;

            if (match || free) return true;
            return false;
        },
    },

    async created() {
        this.$meo.loading = true;

        if (this.$meo.user) {
            await this.$meo.initialised();
        }

        await this.init();

        this.$meo.loading = false;
    },
};
</script>

<style lang="scss"></style>
